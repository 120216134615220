import { CustomerType } from "./type";

// Organizers intial state
const initialAuthState = {
  customers: null,
  customerDropdownList: [],
  isLoading: false,
  activeTab: 0
};

export const reducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case CustomerType.GET_CUSTOMERS_REQUEST:
      return { ...state, isLoading: true };
    case CustomerType.GET_CUSTOMERS_SUCCESS:
      return { ...state, isLoading: false, customers: action.payload.data };
    case CustomerType.GET_CUSTOMERS_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case CustomerType.GET_CUSTOMER_DROPDOWN_REQUEST:
      return { ...state, isLoading: true };
    case CustomerType.GET_CUSTOMER_DROPDOWN_SUCCESS:
      return { ...state, isLoading: false, customerDropdownList: action.payload.data };
    case CustomerType.GET_CUSTOMER_DROPDOWN_FAIL:
      return { ...state, isLoading: false, error: action.error };
    case CustomerType.CHANGE_CUSTOMER_TAB:
      return { ...state, activeTab: action.payload };
    default:
      return state;
  }
};
