// main root endpoint
export const API_ROOT = process.env.REACT_APP_BACKEND_URI;
export const API_ROOT_LOCATION = process.env.REACT_APP_BACKEND_LOCATION_SOCKET_URI;
// all api end points
export const API_END_POINTS = {
  login: "api/admin/auth/login",
  authCheck: "api/admin/auth/check",
  authRefreshCaptcha: "api/admin/auth/refresh-captcha",
  authCheckCaptcha: "api/admin/auth/check-captcha",
  changePassword: "api/admin/auth/change-password",
  getProfile: "api/admin/auth/view-profile",
  updateProfile: "api/admin/auth/update-profile",
  verifyEmail: "api/admin/auth/verify-email",
  resendVerifyEmail: "api/admin/auth/send-link-email-verify-email",
  resetPassword: "api/admin/auth/set-password",
  getSoftwareList: "api/admin/game-upload/game-list",
  UpdateSoftware: "api/admin/advertisement/add",
  UpdateApplicationVersion: "api/admin/auth/update-mobile-game-app-version",
  getApplicationVersionList: "api/admin/auth/version",
  downloadKioskSoftware: "api/admin/game-upload/download",
  resendEmailSetPassword: "api/admin/auth/resend-email-set-password",
  getOperators: "api/admin/operator/list",
  updateOperatorStatus: "api/admin/operator/status",
  createOperator: "api/admin/operator/register",
  updateOperator: "api/admin/operator/update-profile",
  getUsers: "api/admin/user/user-list",
  getUser: "api/admin/user/view-user",
  updateUserStatus: "api/admin/user/change-status",
  createUser: "api/admin/user/create-user",
  updateUser: "api/admin/user/update-profile",
  getUserRole: "api/admin/user/list-role",
  assignUserRole: "api/admin/user/assign-role",
  getTransactions: "api/admin/transaction/transaction-list",
  getAdvertises: "api/admin/advertisement/advertisement-list",
  createAdvertise: "api/admin/advertisement/create-advertisement",
  // updateAdvertiseStatus: "api/admin/advertisement/change-status",
  getAdvertise: "api/admin/advertisement/view-advertisement",
  updateAdvertiseStatus: "api/admin/advertisement/change-status",
  updateAdvertise: "api/admin/advertisement/update-advertisement",
  deleteAdvertise: "api/admin/advertisement/delete-advertisement",
  getSingleOperator: "api/admin/operator/single-operator",
  getCustomers: "api/admin/customer/customer-list",
  getCustomersBalance: "api/admin/transaction/customer-balance",
  updateCustomerStatus: "api/admin/customer/change-status",
  customerDropdown: "api/admin/customer/dropdown",
  adminGetLocations: "api/admin/location/list",
  adminUpdateLocationStatus: "api/admin/location/status",
  adminGetSingleLocation: "api/admin/location/single-location",
  operatorDropdown: "api/admin/operator/dropdown",
  locationDropdown: "api/admin/location/dropdown",
  getDistributors: "api/admin/distributor/distributor-list",
  updateDistributorStatus: "api/admin/distributor/change-status",
  distributorDropdown: "api/admin/distributor/dropdown",
  createDistributor: "api/admin/distributor/distributor-add",
  getSingleDistributor: "api/admin/distributor/view-distributor",
  updateDistributor: "api/admin/distributor/distributor-update",
  getKiosk: "api/admin/kiosk/get-kiosk",
  getKioskModalDropdown: "api/admin/kiosk/dropdown-kiosk",
  createKiosModal: "api/admin/kiosk/save-update-kiosk",
  deleteKiosModal: "api/admin/kiosk/kiosk-model-delete",
  updateKioskByDestributor: "api/admin/kiosk/update-distributor-model",
  getTax: "https://www.promitool.com/tax-zip/api/v1",
  getInvoices: "api/admin/super-admin-invoice/list",
  getInvoice: "api/admin/super-admin-invoice/get",
  addInvoice: "api/admin/super-admin-invoice/create",
  updateInvoiceStatus: "api/admin/super-admin-invoice/update-status",
  invoiceEditItem: "api/admin/super-admin-invoice/update-item",
  invoiceDeleteItem: "api/admin/super-admin-invoice/remove-item",
  createReceipt: "api/admin/super-admin-invoice/add-receipt",
  confirmReceipt: "api/admin/super-admin-invoice/clear-payment",
  completeInvoice: "api/admin/super-admin-invoice/set-invoice-done",
  SendMail: "api/admin/super-admin-invoice/send-mail",
  ViewPDFData: "api/admin/super-admin-invoice/get-pdf",
  getDeleteDoc: "api/admin/auth/delete-image",
  generateKiosk: "api/admin/kiosk/generate-kioskId",
  getKiosks: "api/admin/kiosk/list-kioskId",
  addKiosk: "api/admin/super-admin-invoice/add-terminal",
  removeKiosk: "api/admin/super-admin-invoice/remove-terminal",
  kioskDropdown: "api/admin/kiosk/dropdown-kioskId",
  getAdminStatement: "api/admin/statement/admin-list",
  getSelfAdminStatement: "api/admin/statement/admin-self-list",
  getSelfOperatorStatement: "api/admin/statement/operator-self-list",
  getSuperAdminStatement: "api/admin/statement/super-admin-list",
  getOperatorStatement: "api/admin/statement/operator-list",
  generateStatement: "api/admin/statement/super-admin-add",
  generateDistributorStatment: "api/admin/statement/admin-add",
  getLocationStatement: "api/admin/statement/location-list",
  updateStatmentStatus: "api/admin/statement/update-status",
  UpdateKioskKeys: "api/admin/advertisement/update-kiosk-admin-user-key",
  changeKioskStatus: "api/admin/advertisement/location-outOfService",
  serverMaintenance: "api/admin/advertisement/set-and-cancel-server-under-maintenance",
  getServerMaintenance: "api/admin/auth/get-server-under-maintenance",
  deleteInvoice: "api/admin/super-admin-invoice/delete",
  createReturnInvoice: "api/admin/super-admin-invoice/create-return",
  kioskStatusCount: "api/admin/dashboard/kiosk-count",
  invoiceCount: "api/admin/dashboard/invoice-collection",
  billCount: "api/admin/dashboard/invoice-payment",
  userCount: "api/admin/dashboard/user-count",
  ratioCount: "api/admin/dashboard/get-calculation",
  moneyInOut: "api/admin/dashboard/money-count",
  customerCount: "api/admin/dashboard/customer-count",
  commisionCount: "api/admin/dashboard/commission",
  paymentCount: "api/admin/dashboard/payment",
  dashboardChart: "api/admin/dashboard/chart",
  getLocationDashboard: "api/admin/location/get-dashboard",
  getProfitListDahboard: "api/admin/dashboard/user-list",
  UploadImage: "api/upload-image",
  changeWinningRatio: "api/admin/auth/update-start-winning-ratio",
  cashManagement: "api/admin/kiosk-report/cash-management",
  transactionrReport: "api/admin/kiosk-report/transaction-report",
  cycleItemReport: "api/admin/kiosk-report/cycle-item-report",
  cycleItemReportAll: "api/admin/kiosk-report/cycle-report",
  getDropdown: "api/admin/kiosk-report/date-dropdown",
  getCreditLimit: "api/admin/dashboard/credit-list",
  getBonusListApi: "api/admin/customer/bonus-data",
  //Distributor Panal
  getInvoicesAdmin: "api/admin/admin-invoice/list",
  getInvoiceAdmin: "api/admin/admin-invoice/get",
  addInvoiceAdmin: "api/admin/admin-invoice/create",
  updateInvoiceStatusAdmin: "api/admin/admin-invoice/update-status",
  invoiceEditItemAdmin: "api/admin/admin-invoice/update-item",
  invoiceDeleteItemAdmin: "api/admin/admin-invoice/remove-item",
  createReceiptAdmin: "api/admin/admin-invoice/add-receipt",
  confirmReceiptAdmin: "api/admin/admin-invoice/clear-payment",
  completeInvoiceAdmin: "api/admin/admin-invoice/set-invoice-done",
  SendMailAdmin: "api/admin/admin-invoice/send-mail",
  ViewPDFDataAdmin: "api/admin/admin-invoice/get-pdf",
  addKioskAdmin: "api/admin/admin-invoice/add-terminal",
  removeKioskAdmin: "api/admin/admin-invoice/remove-terminal",
  deleteInvoiceAdmin: "api/admin/admin-invoice/delete",
  createReturnInvoiceAdmin: "api/admin/admin-invoice/create-return",
  viewDistributorStatementPdf: "api/admin/statement/distributor-pdf",
  //Operator Panal
  operatorChangePassword: "api/operator/customer/change-password",
  operatorGetProfile: "api/operator/customer/view-profile",
  operatorUpdateProfile: "api/operator/customer/update-profile",
  operatorGetUsers: "api/operator/user/user-list",
  operatorGetUser: "api/operator/user/view-user",
  operatorUpdateUserStatus: "api/operator/user/change-status",
  operatorCreateUser: "api/operator/user/create-user",
  operatorUpdateUser: "api/operator/user/update-profile",
  operatorGetUserRole: "api/operator/user/list-role",
  operatorAssignUserRole: "api/operator/user/assign-role",
  createLocation: "api/operator/location/register",
  updateLocation: "api/operator/location/update-profile",
  logoutKioskLocation: "api/admin/advertisement/logout",
  operatorGenerateLocationStatement: "api/operator/location/location-statement-generate",
  viewOperatorStatementPdf: "api/admin/statement/operator-pdf"
};
